import { useTranslation } from 'react-i18next';
import { Footer } from '../../components/footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Trans } from "react-i18next";

export const Privacy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  const lang = location.pathname === '/privacy' 
      ? 'en' 
      : location.pathname.replace('/privacy/', '');

  // 遷移
  const handleChange = (e: any) => {
    const path = e.target.value === 'en'
      ? '/privacy' : e.target.value === 'ja'
      ? '/privacy/ja' : '/privacy/ko';
    navigate(path);
  };

  // pathに合わせて言語を変更
  useEffect(() => {
    console.log(lang);
    i18n.changeLanguage(lang);
  }, [location])


  return (
    <div className='bg-[#033039] text-[#fff]'>
      <div className='absolute top-[20px] right-[20px]'>
        <select defaultValue='hidden' onChange={handleChange} className='flex w-[164px] px-4 py-3 justify-center items-center gap-2 bg-[#F2F2F2] rounded-lg text-black'>
          <option value="hidden" hidden>
            Language
          </option>
          <option value="en">English</option>
          <option value="ja">日本語</option>
          <option value="ko">한국어</option>
        </select>
      </div>
      <div className='py-10 flex flex-col items-center'>
        <div className='max-w-4xl px-[1rem] pt-[5rem]'>
          <p className='text-[2.25rem] font-roboto font-[900] leading-10 text-left mb-[2.5rem]'>
          <Trans i18nKey={"privacy.title"} />
          </p>

          <div className='mb-[2.5rem]'>
            <p className='font-roboto text-[1.875rem] font-[700] leading-10 mb-[1.5rem]'>
              <Trans i18nKey={"privacy.t1"} />
            </p>

            <p className='font-roboto leading-6'>
              <Trans i18nKey={"privacy.t2"} components={{
                link1: <a href='https://yuyosoft.com' target='_blank' rel='noopener noreferrer' className='underline cursor-pointer'></a>
              }} />
              
            </p>
          </div>

          <div className='mb-[2.5rem]'>
            <p className='font-roboto text-[1.875rem] font-[700] leading-10 mb-[1.5rem]'>
              <Trans i18nKey={"privacy.t3"} />
            </p>

            <p className='font-roboto leading-6'>
              <Trans i18nKey={"privacy.t4"} />
            </p>
          </div>

          <div className='mb-[2.5rem]'>
            <p className='font-roboto text-[1.875rem] font-[700] leading-10 mb-[1.5rem]'>
            <Trans i18nKey={"privacy.t5"} />
            </p>

            <p className='font-roboto leading-6'>
            <Trans i18nKey={"privacy.t6"} />
            </p>
          </div>

          <div className='mb-[2.5rem]'>
            <p className='font-roboto text-[1.875rem] font-[700] leading-10 mb-[1.5rem]'>
            <Trans i18nKey={"privacy.t7"} />
            </p>

            <p className='font-roboto text-[1.5rem] font-[700] mb-[1rem]'>
            <Trans i18nKey={"privacy.t8"} />
            </p>

            <p className='font-roboto leading-6 mb-[1.5rem]'>
            <Trans i18nKey={"privacy.t9"} />
            </p>

            <p className='font-roboto text-[1.5rem] font-[700] mb-[1rem]'>
            <Trans i18nKey={"privacy.t10"} />
            </p>

            <p className='font-roboto leading-6 mb-[1.5rem]'>
            <Trans i18nKey={"privacy.t11"} />
            </p>
            <p className='font-roboto text-[1.5rem] font-[700] mb-[1rem]'>
            <Trans i18nKey={"privacy.t12"} />
            </p>

            <p className='font-roboto leading-6 mb-[1.5rem]'>
            <Trans i18nKey={"privacy.t13"} />
            </p>
            <p className='font-roboto text-[1.5rem] font-[700] mb-[1rem]'>
            <Trans i18nKey={"privacy.t14"} />
            </p>

            <p className='font-roboto leading-6 mb-[1.5rem]'>
            <Trans i18nKey={"privacy.t15"} />
            </p>
            <p className='font-roboto text-[1.5rem] font-[700] mb-[1rem]'>
            <Trans i18nKey={"privacy.t16"} />
            </p>

            <p className='font-roboto leading-6 mb-[1.5rem]'>
            <Trans i18nKey={"privacy.t17"} />
            </p>
          </div>

          <div className='mb-[2.5rem]'>
            <p className='font-roboto text-[1.875rem] font-[700] leading-10 mb-[1.5rem]'>
            <Trans i18nKey={"privacy.t18"} />
            </p>

            <p className='font-roboto leading-6'>
            <Trans i18nKey={"privacy.t19"} />
            </p>
          </div>
          <div className='mb-[2.5rem]'>
            <p className='font-roboto text-[1.875rem] font-[700] leading-10 mb-[1.5rem]'>
            <Trans i18nKey={"privacy.t20"} />
            </p>

            <p className='font-roboto leading-6 mb-[1rem]'>
            <Trans i18nKey={"privacy.t21"} />
            </p>

            <p className='font-roboto leading-6'>
            <Trans i18nKey={"privacy.t22"} />
            </p>
          </div>
          <div className='mb-[2.5rem]'>
            <p className='font-roboto text-[1.875rem] font-[700] leading-10 mb-[1.5rem]'>
            <Trans i18nKey={"privacy.t23"} />
            </p>

            <p className='font-roboto leading-6 mb-[1rem]'>
            <Trans i18nKey={"privacy.t24"} />
            </p>

            <p className='font-roboto text-[1.5rem] font-[700] mb-[1rem]'>
            <Trans i18nKey={"privacy.t25"} />
            </p>

            <p className='font-roboto leading-6'>
            <Trans i18nKey={"privacy.t26"} />
            </p>
          </div>

          <div className='mb-[2.5rem]'>
            <p className='font-roboto text-[1.875rem] font-[700] leading-10 mb-[1.5rem]'>
            <Trans i18nKey={"privacy.t27"} />
            </p>

            <p className='font-roboto leading-6 mb-[1rem]'>
            <Trans i18nKey={"privacy.t28"} />
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
