interface BubbleProps {
  text: string;
  bubbleBgColor: string;
  textColor: string;
  fontClass: string;
}

export const Bubble = ({text, bubbleBgColor, textColor, fontClass}: BubbleProps) => {
  return (
    <div className='max-w-[337px] w-full relative flex py-[12px] px-[14px] items-start gap-[10px] rounded-[18px]' style={{backgroundColor: `${bubbleBgColor}`}}>
      <p className={`text-lg lg:text-xl ${fontClass}`} style={{color: `${textColor}`}}>{text}</p>
      <svg xmlns="http://www.w3.org/2000/svg" width="33" height="15" viewBox="0 0 33 15" fill="none" className='absolute right-[-15px] bottom-0'>
        <path d="M16.5455 0L0.0910339 15H33C25.2264 10.5629 21.6221 7.28012 16.5455 0Z" fill={`${bubbleBgColor}`}/>
      </svg>
    </div>
  )
}