export const Footer = () => {
  return (
    <div className='bg-[#033039] py-[5rem] flex flex-col items-center'>
      {/* <div className='flex pt-6'>
          <a href='https://twitter.com/wancocam' target='_blank'>
            <img
              className='w-10 mx-5'
              src='/assets/x_icon.png'
              alt='X アカウント'
            />
          </a>
          <a href='https://www.instagram.com/wancocam/' target='_blank'>
            <img
              className='w-10 mx-5'
              src='/assets/insta_icon.png'
              alt='X アカウント'
            />
          </a>
          <a
            href='https://www.facebook.com/profile.php?id=61552478456908'
            target='_blank'
          >
            <img
              className='w-10 mx-5'
              src='/assets/fb_icon.png'
              alt='X アカウント'
            />
          </a>
        </div> */}

      <a
        href='https://yuyosoft.com/'
        target='_blank'
        rel='noopener noreferrer'
        className='mb-5 text-[#fff] text-sm underline cursor-pointer'
      >
        Yuyosoft Innovations Inc.
      </a>
      <p className='text-[#fff] text-sm'>
      Copyright & all rights reserved, Yuyosoft Innovations Inc.{' '}
      </p>
    </div>
  );
};
