import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Home } from './pages/home';
import { NotFound } from './pages/404';
import { Privacy } from './pages/privacy';
import { CookieConsent } from './CookieConsent';
import i18n from './i18n/config'
import { I18nextProvider } from 'react-i18next';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <CookieConsent />
        <Routes>
          <Route index element={<Home />} />
          <Route path='/ja' element={<Home />} />
          <Route path='/ko' element={<Home />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/privacy/ja' element={<Privacy />} />
          <Route path='/privacy/ko' element={<Privacy />} />
          <Route path='/404' element={<NotFound />} />
          <Route path='*' element={<Navigate replace to='/404' />} />
        </Routes>
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
