import { Footer } from '../../components/footer';

export const NotFound = () => {
  return (
    <div className='bg-[#033039] h-[100vh]'>
      <div className='py-10 flex flex-col items-center h-[80vh] relative'>
        <img
          src='/assets/gs-app-icon.png'
          className='w-[5rem] md:w-[7.875rem] mt-[1.5rem]'
        />

        <h1 className='text-[1.5rem] font-limelight text-[#fff] mt-[0.5rem]'>
          Global Spy
        </h1>
        <p className='text-[2.25rem] font-rowdies absolute text-[#fff] top-1/2'>
          Page not found
        </p>
      </div>
      <Footer />
    </div>
  );
};
