import { useTranslation } from 'react-i18next';
import { Bubble } from '../../components/bubble';
import { Footer } from '../../components/footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { Trans } from "react-i18next";

export const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const lang = location.pathname === '/' 
      ? 'en' 
      : location.pathname.replace('/', '');

  // 遷移
  const handleChange = (e: any) => {
    const path = e.target.value === 'en'
      ? '/' : e.target.value === 'ja'
      ? '/ja' : '/ko';
    navigate(path);
  };

  // pathに合わせて言語を変更
  useEffect(() => {
    i18n.changeLanguage(lang);
    document.documentElement.lang = lang;
  }, [location])

  const titleFont = useMemo(() => {
    return lang === 'en' ? 'font-bungee text-[42px] lg:text-5xl leading-tight' : lang === 'ja' ? 'font-dela text-[32px] lg:text-5xl' : 'font-blackHanSans text-[32px] lg:text-5xl';
  }, [location]);

  const descriptionFont = useMemo(() => {
    return lang === 'en' ? 'font-rowdies font-normal text-lg lg:text-2xl leading-tight' : lang === 'ja' ? 'font-mplus font-semibold text-lg lg:text-2xl leading-normal' : 'font-blackHanSans text-lg lg:text-2xl';
  }, [location])

  const bubbleFont = useMemo(() => {
    return lang === 'en' ? 'font-russoOne' : lang === 'ja' ? 'font-dotGothic' : 'font-blackHanSans text-lg';
  }, [location])

  return (
    <div>
      {/* section1 start */}
      <div className={`bg-hero bg-cover bg-center flex flex-col justify-center py-20`}>
        <div className='absolute top-[20px] right-[20px]'>
          <select defaultValue='hidden' onChange={handleChange} className='flex w-[164px] px-4 py-3 justify-center items-center gap-2 bg-[#F2F2F2] rounded-lg'>
            <option value="hidden" hidden>
              Language
            </option>
            <option value="en">English</option>
            <option value="ja">日本語</option>
            <option value="ko">한국어</option>
          </select>
        </div>
        <div className='lg:flex flex-col lg:flex-row justify-between items-center max-w-[864px] xl:max-w-5xl mx-auto mt-[52.5px] lg:mt-0 w-full'>
          {/* left */}
          <div className='flex flex-col items-center justify-center'>
            <h1>
              <img className='hidden lg:block' src='/assets/pc-logo.png' alt='Global Spy Game'/>
              <img className='lg:hidden' src='/assets/sp-logo.png' alt='Global Spy Game'/>
            </h1>
            <div className='items-center mt-4 hidden lg:flex'>
              <a 
                className='w-[152px]'
                href={process.env.REACT_APP_APP_STORE_URL}
                target='_blank'
              >
                <img className='w-full' src='/assets/app_store_badge_en.svg'/>
              </a>
              <a
                className='w-[189px]'
                href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL}
                target='_blank'
              >
                <img
                  src='/assets/google_play_badge_en.png'
                  className='w-full'
                />
              </a>
            </div>
          </div>
          {/* right */}
          <div className='w-full max-w-[260px] lg:max-w-[408.202px] mx-auto mt-10 lg:m-0'>
            <img
              src='/assets/mock/en/1.webp'
              className='w-full'
            />
          </div>
          <div className='flex flex-col gap-6 mt-10 items-center lg:hidden'>
            <a 
              className='w-[152px]'
              href={process.env.REACT_APP_APP_STORE_URL}
              target='_blank'
            >
              <img className='w-full' src='/assets/app_store_badge_en.svg'/>
            </a>
            <a
              className='w-[189px]'
              href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL}
              target='_blank'
            >
              <img
                src='/assets/google_play_badge_en.png'
                className='w-full'
              />
            </a>
          </div>
        </div>
      </div>
      {/* section1 end */}

      {/* section2 start */}
      <div className='bg-spGreen md:bg-green py-20 bg-cover bg-center px-4'>
        <div className='lg:flex flex-col lg:flex-row justify-center items-center lg:max-w-[864px] xl:max-w-5xl mx-auto gap-14'>
          {/* left */}
          <div className='flex flex-col gap-6 w-full max-w-[400px] lg:max-w-[560px] mx-auto lg:mx-0'>
            <h2 className={`text-[#22FFAF] ${titleFont}`}>
              <Trans i18nKey={"section2.title"} />
            </h2>
            <p className={`text-[#22FFAF] ${descriptionFont}`}>
              <Trans i18nKey={"section2.description"} />
            </p>
          </div>
          {/* right */}
          <div className='flex flex-col items-center mx-auto mt-10 lg:m-0'>
            <div className='w-full max-w-[320px] lg:w-[408.202px]'>
              <img src={`/assets/mock/${i18n.language}/6.webp`} className='w-full'/>
            </div>
          </div>
        </div>
        <div className='flex gap-2 items-start justify-center mt-10'>
          <Bubble text={t('section2.bubble')} bubbleBgColor='#C3F1D6' textColor='#033039' fontClass={bubbleFont} />
          <img src="/assets/spy.png" alt="" />
        </div>
      </div>
      {/* section2 end */}

      {/* section3 start */}
      <div className='bg-spBlue md:bg-blue py-20 bg-cover bg-center px-4'>
        <div className='lg:flex flex-col lg:flex-row justify-center items-center lg:max-w-[864px] xl:max-w-5xl mx-auto gap-14'>
          {/* left */}
          <div className='flex flex-col gap-6 w-full max-w-[400px] lg:max-w-[560px] mx-auto lg:mx-0'>
            <h2 className={`text-[#DDEAFF] ${titleFont}`}>
              <Trans i18nKey={"section3.title"} />
            </h2>
            <p className={`text-[#DDEAFF] ${descriptionFont}`}>
              <Trans i18nKey={"section3.description"} />
            </p>
          </div>
          {/* right */}
          <div className='flex flex-col items-center mx-auto mt-10 lg:m-0'>
            <div className='w-full max-w-[320px] lg:w-[408.202px]'>
              <img src={`/assets/mock/${i18n.language}/5.webp`} className='w-full'/>
            </div>
          </div>
        </div>
        <div className='flex gap-2 items-end justify-center mt-10'>
          <Bubble text={t('section3.bubble')} bubbleBgColor='#DDEAFF' textColor='#305085' fontClass={bubbleFont} />
          <img src="/assets/spy.png" alt="" />
        </div>
      </div>
      {/* section3 end */}

      {/* section4 start */}
      <div className='bg-spGreen md:bg-green py-20 bg-cover bg-center px-4'>
        <div className='lg:flex flex-col lg:flex-row justify-center items-center lg:max-w-[864px] xl:max-w-5xl mx-auto gap-14'>
          {/* left */}
          <div className='flex flex-col gap-6 w-full max-w-[400px] lg:max-w-[560px] mx-auto lg:mx-0'>
            <h2 className={`text-[#22FFAF] ${titleFont}`}>
              <Trans i18nKey={"section4.title"} />
            </h2>
            <p className={`text-[#22FFAF] ${descriptionFont}`}>
              <Trans i18nKey={"section4.description"} />
            </p>
          </div>
          {/* right */}
          <div className='flex flex-col items-center mx-auto mt-10 lg:m-0'>
            <div className='w-full max-w-[320px] lg:w-[408.202px]'>
              <img src={`/assets/mock/${i18n.language}/7.webp`} className='w-full'/>
            </div>
          </div>
        </div>
        <div className='flex gap-2 items-end justify-center mt-10'>
          <Bubble text={t('section4.bubble')} bubbleBgColor='#C3F1D6' textColor='#033039' fontClass={bubbleFont} />
          <img src="/assets/spy.png" alt="" />
        </div>
      </div>
      {/* section4 end */}

      {/* section5 start */}
      <div className='bg-spBlue md:bg-blue py-20 bg-cover bg-center px-4'>
        <div className='lg:flex flex-col lg:flex-row justify-center items-center lg:max-w-[864px] xl:max-w-5xl mx-auto gap-14'>
          {/* left */}
          <div className='flex flex-col gap-6 w-full max-w-[400px] lg:max-w-[560px] mx-auto lg:mx-0'>
            <h2 className={`text-[#DDEAFF] ${titleFont}`}>
              <Trans i18nKey={"section5.title"} />
            </h2>
            <p className={`text-[#DDEAFF] ${descriptionFont}`}>
              <Trans i18nKey={"section5.description"} />
            </p>
          </div>
          {/* right */}
          <div className='flex flex-col items-center mx-auto mt-10 lg:m-0'>
            <div className='w-full max-w-[320px] lg:w-[408.202px]'>
              <img src={`/assets/mock/${i18n.language}/3.webp`} className='w-full'/>
            </div>
          </div>
        </div>
        <div className='flex gap-2 items-end justify-center mt-10'>
          <Bubble text={t('section5.bubble')} bubbleBgColor='#DDEAFF' textColor='#305085' fontClass={bubbleFont} />
          <img src="/assets/spy.png" alt="" />
        </div>
      </div>
      {/* section5 end */}

      {/* section6 start */}
      <div className='bg-spGreen md:bg-green bg-cover bg-center py-20 px-4'>
        <div className='lg:flex flex-col lg:flex-row justify-center items-center lg:max-w-[864px] xl:max-w-5xl mx-auto gap-14'>
          {/* left */}
          <div className='flex flex-col gap-6 w-full max-w-[400px] lg:max-w-[560px] mx-auto lg:mx-0'>
            <h2 className={`text-[#22FFAF] ${titleFont}`}>
              <Trans i18nKey={"section6.title"} />
            </h2>
            <p className={`text-[#22FFAF] ${descriptionFont}`}>
              <Trans i18nKey={"section6.description"} />
            </p>
          </div>
          {/* right */}
          <div className='flex flex-col items-center mx-auto mt-10 lg:m-0'>
            <div className='w-full max-w-[320px] lg:w-[408.202px]'>
              <img src={`/assets/mock/${i18n.language}/10.webp`} className='w-full'/>
            </div>
          </div>
        </div>
        <div className='flex gap-2 items-end justify-center mt-10'>
          <Bubble text={t('section6.bubble')} bubbleBgColor='#C3F1D6' textColor='#033039' fontClass={bubbleFont} />
          <img src="/assets/spy.png" alt="" />
        </div>
      </div>
      {/* section6 end */}

      {/* section7 start */}
      <div className='bg-radialRightBlue bg-cover bg-center py-40 px-4'>
        <div className='w-full max-w-[358px] lg:max-w-[720px] mx-auto' style={{aspectRatio: '1/0.623'}}>
          {i18n.language === 'en' 
            ? <iframe 
                src="https://www.youtube.com/embed/dQDvRD8c7-U"
                title="App Preview - Global Spy Game" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                referrerPolicy="strict-origin-when-cross-origin" 
                allowFullScreen
                className='w-full h-full'
              />
            : lang === 'ja'
            ? <iframe 
                src="https://www.youtube.com/embed/MJxQnVuEoVI" 
                title="モバイル・スパイゲーム「グローバルスパイゲーム」プレビュー" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                referrerPolicy="strict-origin-when-cross-origin" 
                allowFullScreen
                className='w-full h-full'
              />
            : <iframe 
                src="https://www.youtube.com/embed/bZGCLCH01f4" 
                title="App preview - 글로벌 스파이 게임" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                referrerPolicy="strict-origin-when-cross-origin" 
                allowFullScreen
                className='w-full h-full'
              />
          }
          
        </div>
      </div>
      {/* section7 end */}

      {/* section8 start */}
      {i18n.language === 'en' && 
        <div className='bg-orange bg-cover bg-center py-40'>
          <div className='w-full max-w-[358px] lg:max-w-[720px] mx-auto' style={{aspectRatio: '1/0.623'}}>
            <iframe 
              src="https://www.youtube.com/embed/LuseNZUo_ac" 
              title="WHERE ARE THEY?? | Global Spy" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerPolicy="strict-origin-when-cross-origin" 
              allowFullScreen
              className='w-full h-full'
            />
          </div>
        </div>
      }
      {/* section8 end */}

      {/* section9 start */}
      <div className='bg-gradient-to-r from-green-500 via-purple-500 to-indigo-500 flex flex-col items-center py-20'>
        <div className='lg:flex flex-col items-center'>
          <img className='w-[240px] mx-auto' src="/assets/logo-text.png" alt="" />
          <div className='mt-14'>
            <img src="/assets/gs-app-icon.png" alt="" className='w-[150px] h-[150px] mx-auto' />
          </div>
          <div className='flex items-center mt-14 gap-4'>
            <a 
              className='w-[152px]'
              href={process.env.REACT_APP_APP_STORE_URL}
              target='_blank'
            >
              <img className='w-full' src='/assets/app_store_badge_en.svg'/>
            </a>
            <a
              className='w-[189px]'
              href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL}
              target='_blank'
            >
              <img
                src='/assets/google_play_badge_en.png'
                className='w-full'
              />
            </a>
          </div>
        </div>
      </div>
      {/* section9 end */}

      {/* section10 start */}
      {i18n.language === 'en' && 
        <div className='bg-[#8C7E6D] flex flex-col items-center py-20 px-4'>
          <div className='flex flex-col items-center gap-8'>
            <h2 className='text-5xl lg:text-[54px] text-[#F3EDDC] font-limeLight text-center'>City Spy Game</h2>
            <p className='text-[#F3EDDC] text-lg font-rowdies text-center'>Don’t forget to try our other game, City Spy Game! These are the two best spy games for mobile devices.</p>
            <img src="/assets/cs-app-icon.png" className='w-40 h-40' alt="" />
            <a 
              className='w-[152px]'
              href={process.env.REACT_APP_APP_STORE_URL_CS}
              target='_blank'
            >
              <img className='w-full' src='/assets/app_store_badge_en.svg'/>
            </a>
            <a
              className='w-[189px]'
              href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL_CS}
              target='_blank'
            >
              <img
                src='/assets/google_play_badge_en.png'
                className='w-full'
              />
            </a>
          </div>
        </div>
      }
      {/* section10 end */}
      <Footer />
    </div>
  );
};
